import "./Stats.css";
import banner_1 from '../../../../assets/images/stats/stats-1.webp'
import banner_2 from '../../../../assets/images/stats/stats-2.webp'

const list = [
    {
        title: '141.000',
        text: 'Подписчиков в&nbsp;Инстаграмм',
    },
    {
        title: '80.000',
        text: 'Cтудентов из&nbsp;более чем 60&nbsp;фитнес-клубов (Россия, Болгария, Польша, Израиль)',
    },
    {
        title: '6.000+',
        text: 'Выпускников школы Move4s, многие стали известными фитнес-экспертами и&nbsp;подняли стоимость своих услуг в&nbsp;3-5&nbsp;раз.',
    },

]
function Stats() {


    return (
        <div className={`stats`} >
            <div className="stats__container">
                <img className="stats__banner" src={banner_1} alt="Move4S" />
                <div className="stats__texts">
                    <h3 className="stats__title">Move4S</h3>
                    <p className="stats__text">Благодаря, полученным знаниям и&nbsp;огромному опыту (более 35&nbsp;лет в&nbsp;фитнесе) мне удалось создать школу восстановительного фитнеса, пропагандирующую бережную систему восстановления тела.</p>
                </div>
            </div>
            <div className="stats__container stats__container_2">
                <div className="stats__texts">
                    <h4 className="stats__subtitle">Среди моих учеников, последователей и&nbsp;единомышленников:</h4>
                    <div className="stats__list">
                        {list.map((item, i) => (
                            <div className="stats__list-item" key={`stats__list-item${i}`}>
                                <svg className="stats__list-item-bulet" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 24C10.3608 24 8.81569 23.6863 7.36471 23.0588C5.92157 22.4314 4.64706 21.5647 3.54118 20.4588C2.43529 19.3529 1.56863 18.0784 0.941176 16.6353C0.313726 15.1843 0 13.6392 0 12C0 10.3608 0.313726 8.81961 0.941176 7.37647C1.56863 5.92549 2.43137 4.64706 3.52941 3.54118C4.63529 2.43529 5.9098 1.56863 7.35294 0.941176C8.80392 0.313726 10.349 0 11.9882 0C13.6275 0 15.1725 0.313726 16.6235 0.941176C18.0745 1.56863 19.3529 2.43529 20.4588 3.54118C21.5647 4.64706 22.4314 5.92549 23.0588 7.37647C23.6863 8.81961 24 10.3608 24 12C24 13.6392 23.6863 15.1843 23.0588 16.6353C22.4314 18.0784 21.5647 19.3529 20.4588 20.4588C19.3529 21.5647 18.0745 22.4314 16.6235 23.0588C15.1804 23.6863 13.6392 24 12 24ZM10.6824 17.7412C10.8863 17.7412 11.0706 17.6941 11.2353 17.6C11.4 17.5059 11.5451 17.3647 11.6706 17.1765L17.2 8.45882C17.2706 8.34118 17.3373 8.21569 17.4 8.08235C17.4627 7.94902 17.4941 7.81569 17.4941 7.68235C17.4941 7.40784 17.3922 7.19216 17.1882 7.03529C16.9843 6.87059 16.7569 6.78824 16.5059 6.78824C16.1608 6.78824 15.8784 6.96863 15.6588 7.32941L10.6353 15.4L8.24706 12.3176C8.09804 12.1216 7.95294 11.9882 7.81176 11.9176C7.67843 11.8471 7.52549 11.8118 7.35294 11.8118C7.08627 11.8118 6.86274 11.9098 6.68235 12.1059C6.50196 12.2941 6.41176 12.5216 6.41176 12.7882C6.41176 12.9216 6.43529 13.0549 6.48235 13.1882C6.53725 13.3137 6.60784 13.4353 6.69412 13.5529L9.64706 17.1765C9.80392 17.3804 9.96471 17.5255 10.1294 17.6118C10.2941 17.698 10.4784 17.7412 10.6824 17.7412Z" fill="#90B0D0" />
                                </svg>
                                <div className="stats__list-item-texts">
                                    <h5 className="stats__list-item-title" dangerouslySetInnerHTML={{
                                        __html: item.title,
                                    }}></h5>
                                    <p className="stats__list-item-text" dangerouslySetInnerHTML={{
                                        __html: item.text,
                                    }}></p>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <img className="stats__banner-2" src={banner_2} alt="Среди моих учеников" />
            </div>
        </div >
    );
}

export default Stats;