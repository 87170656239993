import "./AboutUs.css";
import banner from '../../../../assets/images/about-us/about-us.webp'

function AboutUs() {


    return (
        <div className={`about-us`} >
            <h3 className="about-us__title">О нас</h3>
            <div className="about-us__content">
                <img className="about-us__banner" src={banner} alt="О нас" />
                <div className="about-us__texts">
                    <div className="about-us__line" />
                    <div className="about-us__texts-container">
                        <h4 className="about-us__texts-title">Здравствуйте!</h4>
                        <p className="about-us__texts-text about-us__texts-text_1">Я, Леля Савосина&nbsp;&mdash; врач и&nbsp;фитнес-эксперт. Приветствую Вас на&nbsp;нашем сайте. Здесь Вы&nbsp;можете приобрести фитнес-оборудование, диагностическое оборудование <b>&quot;Скрининг&nbsp;тела&quot;</b> и&nbsp;полезные прикладные вещи для правильных тренировок. Пройти обучение для фитнес-тренера в&nbsp;школе восстановительного фитнеса <b>Move4s.</b></p>
                        <p className="about-us__texts-text about-us__texts-text_2">Мне 60&nbsp;лет и&nbsp;я&nbsp;знаю, как заботиться о&nbsp;себе и&nbsp;помочь Вам и&nbsp;Вашим клиентам!</p>
                    </div>
                    {/* <svg className="about-us__bg-icon" width="85" height="96" viewBox="0 0 85 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 27V1.5L43 29L81 1.5V27L43 54.5L3.5 27Z" fill="#93D4D2" />
                        <path d="M3.5 65.5V40L43 67.5L81 40V65.5L43 93L3.5 65.5Z" fill="#93D4D2" />
                    </svg> */}

                </div>
            </div>
        </div >
    );
}

export default AboutUs;