import "./AboutMe.css";
import banner from '../../../../assets/images/about-me/about-me.webp'

function AboutMe() {


    return (
        <div className={`about-me`} >
            <img className="about-me__banner" src={banner} alt="Мотивация" />
            <div className="about-me__texts">
                <div className="about-me__line" />
                <div className="about-me__texts-container">
                    <p className="about-me__text">Дорогие мои, за&nbsp;свою профессиональную жизнь я&nbsp;консультировала большое количество людей: и&nbsp;спортсменов, и&nbsp;бизнесменов, и&nbsp;домохозяек, и&nbsp;детей, и&nbsp;людей, перенесших операции, находящихся в&nbsp;реабилитационном периоде.</p>
                    <p className="about-me__text">Моей мотивацией в&nbsp;работе была одна цель&nbsp;&mdash; помочь Вам стать активными и&nbsp;здоровыми в&nbsp;любом возрасте. <br/>Надеюсь, то&nbsp;<b>фитнес-оборудование</b>, которое представлено на&nbsp;нашем сайте, поможет нам совместно дойти до&nbsp;этой цели.</p>
                </div>
            </div>
        </div >
    );
}

export default AboutMe;